import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SignaliseSigningTheRightPath2020 from '../../assets/SignaliseSigningTheRightPathReport2020.pdf';
import SignaliseCPDCertificate from '../../assets/Signalise_CPD_covid19_certificate.pdf';
import SignaliseCPDReadingList from '../../assets/Signalise_CPD_covid19_reading_list.pdf';
import SignaliseCPDWorksheet from '../../assets/Signalise_CPD_covid19_worksheet.pdf';

import hiFive from './hi5.png';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { StaticPage, TopbarContainer } from '..';
import {
  Page,
  LayoutSingleColumn,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import config from '../../config';

import css from './ResourcesPage.module.css';

const ResourcesPageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ResourcesPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  return (
    <StaticPage title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ResourcesPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            <div className={css.intro}>
              <h1 className={css.pageTitle}>Resources</h1>
              <img src={hiFive} alt="Two Signalise hands happy" className={css.hiFive} />
            </div>
            <div className={css.contentContainer}>
              <div className={css.contentMain}>
                <section className={css.contentSection}>
                  <h2>Our Report</h2>
                  <p>
                    We undertook a survey of users across the five boroughs of Merseyside:
                    Liverpool, Wirral, Sefton, Knowsley & St Helens. 
                  </p>
                  <p>
                    {' '}
                    The findings show a number of problems with interpreting delivery from a lack of
                    availability, poor quality and a lack of understanding about Deaf people’s
                    communication needs.
                  </p>
                  <p>
                    We asked respondents what a perfect interpreting service would look like. This
                    formed part of the service design for the user-owned Signalise platform. Answers
                    included: user-led, easy to use and fair and transparent.{' '}
                  </p>
                  <a href={SignaliseSigningTheRightPath2020} download>
                    Download the report.
                  </a>
                </section>
                <section className={css.sectionContent}>
                  <h2>Co-ops, Covid-19 and interpreting in the 'new economy'</h2>
                  <p>
                    We have seen lots of changes to interpreting due to Covid-19. We have recorded
                    two versions of a presentation. One is free CPD training for interpreters (with
                    extra resources provided below) and the second is a BSL summary version that
                    includes all the important points. The presenters are Jen Smith and Nicky Evans.
                    In these videos we discuss:{' '}
                  </p>
                  <ul className={css.list}>
                    <li>co-operatives</li>
                    <li>Covid-19: what happened to interpreting and access for Deaf people</li>
                    <li>trends in interpreting provision since the pandemic</li>
                    <li>the gig economy vs tech for good</li>
                    <li>the new economy</li>
                    <li>why we need a co-op for interpreting services</li>
                  </ul>
                </section>
                <section className={css.sectionContent}>
                  <h2>CPD</h2>
                  <div className={css.videoSection}>
                    <div className={css.videoContainer}>
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube-nocookie.com/embed/w4GLsGA4w9M?rel=0&origin=https://signalise.coop"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="embedded YouTube clip of a Signalise report on Co-ops and Covid-19"
                      ></iframe>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <a href={SignaliseCPDWorksheet} download>
                        Worksheet
                      </a>
                    </li>
                    <li>
                      <a href={SignaliseCPDReadingList} download>
                        Reading List
                      </a>
                    </li>
                    <li>
                      <a href={SignaliseCPDCertificate} download>
                        Certificate
                      </a>
                    </li>
                  </ul>
                </section>
                <section className={css.sectionContent}>
                  <h2>BSL Summary</h2>
                  <div className={css.videoSection}>
                    <div className={css.videoContainer}>
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube-nocookie.com/embed/-gyq0LNJGcU?rel=0&origin=https://signalise.coop"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="embedded YouTube clip of a BSL summary of Signalise report"
                      ></iframe>
                    </div>
                  </div>
                </section>
              </div>

              {/* <img src={ hiFive } alt="Signalise hands happy" className={ css.hiFive }/> */}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const { bool } = PropTypes;

ResourcesPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const ResourcesPage = compose(connect(mapStateToProps), injectIntl)(ResourcesPageComponent);

export default ResourcesPage;
